// styles overrides
.rdrDateRangeWrapper {
    .rdrDateDisplayWrapper{
        display: none;
    }

    .rdrMonthAndYearPickers select {
        color: black;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: capitalize;
    }

    .rdrMonthAndYearWrapper{
        padding-bottom: 10px;
        padding-top: 0;

        .rdrNextPrevButton{
            background-color: black;
            width: 30px;
            height: 30px;

            &.rdrPprevButton i {
               border-color:  transparent rgb(255, 255, 255) transparent transparent;
            }

            &.rdrNextButton i {
                margin: 0 0 0 10px;
                border-color:  transparent transparent transparent rgb(255 255 255);
             }
        }
    }

    .rdrDayToday .rdrDayNumber span:after {
        background: #21c5fd;
    }

    .rdrDateDisplayWrapper {
        border-radius: 5px;
    }

    .rdrWeekDay {
        color:  #21c5fd;
        text-transform: capitalize;
        font-size: 1.2em;
    }

    .rdrMonth {
        width: 32em;
    }

    .rdrDay {
        margin-top: 6px;
        font-size: 1.2em;
    }

    .rdrStartEdge,
    .rdrEndEdge,
    .rdrInRange {
        color:  #21c5fd !important;
    }

    .rdrStartEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrEndEdge  {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }

    .rdrEndEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
    }

    .rdrDayStartPreview, 
    .rdrDayEndPreview,
    .rdrDayInPreview {
        color:  #21c5fd !important;
    }
    
    .rdrDayStartPreview {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }
    
    .rdrDayEndPreview,
    .rdrDayEndPreview .rdrDayInPreview {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
    }
}