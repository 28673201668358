// Colors
$success-300: #5da862;
$success-200: #d6e9d7;
$danger-300: #be0000;
$warning-300: #f1b000;
$interaction-300: #0094ca;
$interaction-200: #21c5fd;
$interaction-100: #83dfff;
$text-400: #020303;
$text-300: #263238;
$text-200: #37474f;
$text-100: #455a64;
$neutral-500: #546e7a;
$neutral-400: #607d8b;
$neutral-300: #78909c;
$neutral-200: #90a4ae;
$neutral-100: #b0bec5;
$grey-200: #eceff1;
$grey-300: #cfd8dc;
$white: #ffffff;
$black: #000000;

$box-shadow: 0 6px 24px 0 rgba(2, 3, 3, 0.16);

// Variables margin and padding
$spacer: 1;
$spaceamounts: (
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32
);
$sides: (
    "": "all",
    "t": "top",
    "b": "bottom",
    "l": "left",
    "r": "right",
);

@each $space in $spaceamounts {
    @each $prefix, $value in $sides {
        $property: if($prefix == "", "", -#{$value});

        /// margin, where $prefix one of: "": "all", "t": "top", "b": "bottom", "l": "left", "r": "right" while $space has value from 0 to 20
        /// EXAMPLE: ".mt-3" (margin-top: 3px) ".m-15" (margin: 15px)
        .m#{$prefix}-#{$space} {
            margin#{$property}: #{$space * $spacer}px !important;
        }

        /// padding, where $prefix one of: "": "all", "t": "top", "b": "bottom", "l": "left", "r": "right" while $space has value from 0 to 20
        /// EXAMPLE: ".pb-5" (padding-bottom: 5px) ".p-18" (padding: 18px)
        .p#{$prefix}-#{$space} {
            padding#{$property}: #{$space * $spacer}px !important;
        }
    }
}
